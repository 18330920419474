import { api } from './api';

const ENTITY = "Budgets"

export const budgetApi = api.injectEndpoints({
   tagTypes: ['Budget'],
   endpoints: (builder) => ({
      getBudgets: builder.query({
         query: () => {
            const params = { '$expand': 'Project($select=AlternativeName,Name,Id),WorkItem($select=Name,Id,AssignedTo)' };
            params['$orderby'] = 'CreatedAt'
            return {
               url: `${ENTITY}`,
               params: { ...params }
            }
         },
         providesTags: [{ type: 'Budget' }]
      }),

      getBudget: builder.query({
         query: (Id) => {
            const params = { '$expand': 'Project($select=AlternativeName,Name,Id),WorkItem($select=Name,Id,AssignedTo)' };
            params['$orderby'] = 'CreatedAt'
            return {
               url: `${ENTITY}(${Id})`,
               params: { ...params }
            }
         },
         providesTags: [{ type: 'Budget' }]
      }),

      createBudget: builder.mutation({
         query: ({ body }) => {

            return {
               url: `${ENTITY}`,
               method: 'POST',
               body: body,
            }
         },
         invalidatesTags: [{ type: 'Budget' }]
      }),

      updateBudget: builder.mutation({
         query: ({ id, body, etag }) => {
            let clearEtag = etag ? etag.replaceAll('\\', '') : etag
            return {
               url: `${ENTITY}(${id})`,
               method: 'PATCH',
               body: body,
               headers: { 'if-match': clearEtag }
            }
         },
         invalidatesTags: [{ type: 'Budget' }]
      }),

      deleteBudget: builder.mutation({
         query: (id) => {
            return {
               url: `${ENTITY}(${id})`,
               method: 'DELETE',
            }
         },
         // invalidatesTags: [{ type: 'Budget' }]
      }),

      recalculateBudget: builder.mutation({
         query: () => {
            return {
               url: `Budgets/TimeTracking.DataEntities.RecalculateBudgets`,
               method: 'POST',
            }
         },
         invalidatesTags: [{ type: 'Budget' }]
      }),

      createAttachment: builder.mutation({
         query: ({ id, entity, fileName, extension }) => {
            return {
               url: `Attachments`,
               method: 'POST',
               body: { EntityId: id, OdataEntity: entity, FileName: fileName, FileExtension: extension },
            }
         },
         // invalidatesTags: [{ type: 'Budget' }]
      }),

      addAttachment: builder.mutation({
         query: ({ id, file }) => {
            return {
               url: `Attachments(${id})/TimeTracking.DataEntities.AddAttachment`,
               method: 'POST',
               body: file,
               headers: { 'Content-Type': 'application/octet-stream' },
            }
         },
         // invalidatesTags: [{ type: 'Budget' }]
      }),

      clearAttachment: builder.mutation({
         query: ({ id }) => {
            return {
               url: `Attachments(${id})/TimeTracking.DataEntities.ClearAttachment`,
               method: 'POST',
               body: {},
            }
         },
         invalidatesTags: [{ type: 'Budget' }]
      }),

      removeAttachment: builder.mutation({
         query: ({ id }) => {
            return {
               url: `Attachments(${id})`,
               method: 'DELETE',
            }
         },
         invalidatesTags: [{ type: 'Budget' }]
      }),
   })
})

export const { useGetBudgetsQuery, useUpdateBudgetMutation, useDeleteBudgetMutation, useGetBudgetQuery, useRecalculateBudgetMutation, useCreateBudgetMutation, useCreateAttachmentMutation, useAddAttachmentMutation, useClearAttachmentMutation, useRemoveAttachmentMutation } = budgetApi

// types
import { createSlice } from '@reduxjs/toolkit';
// import THEME_LIGHT from 'themes/index';
// import { THEME_DARK } from 'themes/index';

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';
// initial state
const initialState = {
    openItem: [''],
    openComponent: 'buttons',
    drawerOpen: sessionStorage.getItem('isMenuOpen') === 'true',
    componentDrawerOpen: true,
    pageName: 'Assigned To Me',
    theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : THEME_LIGHT
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setPageName(state, action) {
            state.pageName = action.payload;
        },

        activeItem(state, action) {
            state.openItem = action.payload.openItem;
        },

        activeComponent(state, action) {
            state.openComponent = action.payload.openComponent;
        },

        setTheme(state, action) {
            state.theme = [THEME_DARK, THEME_LIGHT].includes(action.payload) ? action.payload : THEME_LIGHT;
            localStorage.setItem('theme', state.theme);
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
            sessionStorage.setItem('isMenuOpen', action.payload.drawerOpen)
        },

        openComponentDrawer(state, action) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        }
    }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, setPageName, setTheme } = menu.actions;

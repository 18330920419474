import React, { useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomSelectFilter from 'components/Table/CustomSelectFilter';

export const operatorsSelect = [
    { label: 'In List', operator: 'inlist' },
    { label: 'Not In List', operator: 'notinlist' }
];
export const operatorsString = [
    { label: 'Contains', operator: 'contains' },
    { label: 'Starts With', operator: 'startsWith' },
    { label: 'Ends With', operator: 'endsWith' },
    { label: 'Equals', operator: 'eq' },
    { label: 'Does Not Equals', operator: 'neq' }
];

const TableStringFilter = ({ onChange, filterValue, isOnlyContains = false, ...other }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const filterMenuBox = useRef();

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleFilterOperatorChange = (operator) => {
        onChange({ ...filterValue, value: '', operator: operator });
    };
    const onChangeInputValue = (e) => {
        onChange({ ...filterValue, value: e.target.value });
    };
    const isStringFilter = operatorsString.findIndex((item) => filterValue.operator == item.operator) !== -1;

    const args = { ...other, onChange, filterValue };
    return (
        <Stack
            ref={filterMenuBox}
            sx={{
                borderTop: '1px solid ',
                borderColor: 'customColorTableStringFilter.colorBorder',
                backgroundColor: 'customColorTableStringFilter.colorBgWhite',
                minHeight: '41px',
                px: '4px',
                py: '2px',
                position: 'relative',
                overflow: 'unset'
            }}
        >
            <Box
                sx={{
                    p: 0.5,
                    backgroundColor: 'customColorTableStringFilter.colorBgWhite',
                    borderRadius: '5px',
                    height: '100%',
                    width: '100%'
                }}
            >
                <Stack direction="row" alignItems="start">
                    {isStringFilter || isOnlyContains ? (
                        <TextField
                            onChange={onChangeInputValue}
                            value={filterValue.value ? filterValue.value : ''}
                            placeholder={other?.filterEditorProps?.placeholder || 'All'}
                            size="small"
                            autoComplete="off"
                            fullWidth
                            sx={{
                                mr: '5px',
                                '& .MuiInputBase-sizeSmall': {
                                    paddingBottom: '2px !important',
                                    paddingTop: '2px !important',
                                    minHeight: '26px'
                                }
                            }}
                        />
                    ) : (
                        <CustomSelectFilter {...args} />
                    )}
                    {!isOnlyContains && (
                        <Button
                            onClick={handleOpenMenu}
                            sx={{
                                flexShrink: 0,
                                ml: 'auto',
                                mb: 'auto',
                                mt: '2px',
                                color: 'customColorTableStringFilter.colorButton',
                                minWidth: '15px'
                            }}
                        >
                            <FilterOutlined />
                        </Button>
                    )}
                </Stack>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                    {[...operatorsSelect, ...operatorsString]?.map((operator) => (
                        <MenuItem
                            key={operator.label}
                            sx={{
                                background:
                                    operator.operator === filterValue?.operator ? 'customColorTableStringFilter.colorMenuItemRGBA' : ''
                            }}
                            onClick={() => {
                                handleFilterOperatorChange(operator.operator);
                                handleCloseMenu();
                            }}
                        >
                            {operator.label}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </Stack>
    );
};

export default TableStringFilter;

import React, { useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useCreateRecordMutation } from 'services/tracker';
import { useSelector } from 'react-redux';
import WorkItemSelect from 'components/WorkItemSelect';

const StartTrackerForm = ({ startPlaying }) => {
    const userId = useSelector((state) => state.auth.userId);
    const [selectedTask, setSelectedTask] = useState(null);
    const [comment, setComment] = useState('');
    const [createTransaction] = useCreateRecordMutation();
    const commentRef = useRef();

    const isValidComment = useMemo(() => {
        if (!comment) return true;
        return !!comment.match(/^[a-zA-Z0-9_ -?:/|]{3,128}$/);
    }, [comment]);

    const isDisabledStart = useMemo(() => {
        if (!comment && !selectedTask) {
            return true;
        } else if (comment) {
            return !isValidComment;
        } else if (selectedTask) {
            return false;
        }
    }, [comment, selectedTask]);

    const onStart = async () => {
        let request = { userId };
        if (selectedTask && comment) {
            request.body = { Comment: comment, MinutesQuantity: 0, WorkItemId: selectedTask };
        } else if (selectedTask) {
            request.body = { MinutesQuantity: 0, WorkItemId: selectedTask };
        } else {
            request.body = { Comment: comment, MinutesQuantity: 0 };
        }

        try {
            let res = await createTransaction(request);
            startPlaying(res.data);
        } catch (e) {
            if (e?.status != 401) {
                alert(`An error occurred: ${err?.data?.error?.message}`);
            }
        }
    };

    const handleTaskChange = (val) => {
        setSelectedTask(val);
        if (val) {
            let x = setTimeout(() => {
                commentRef.current.focus();
                clearTimeout(x);
            }, 0);
        }
    };

    return (
        <Stack direction="row" alignItems="end" sx={{ width: '100%' }}>
            {/* <Autocomplete
                disablePortal
                id="taskSearch"
                sx={{
                    width: 240,
                    '& .MuiAutocomplete-endAdornment': {
                        top: 'calc(50% - 18px)',
                        '& button': { width: '32px', borderRadius: '100%', height: '32px' }
                    }
                }}
                options={options && !isFetching ? options : []}
                autoHighlight
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={onChangeSearch}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option?.Name}
                loading={isFetching}
                onInputChange={onSearchChange}
                renderOption={(props, option) => <ProjectOption props={props} option={option} key={option.Id} />}
                renderInput={(params) => <TextField variant="standard" {...params} placeholder="Enter Task or Project Name" />}
            /> */}

            <WorkItemSelect debounceMs={200} selectedWorktemId={selectedTask} onChange={handleTaskChange} />

            <Typography sx={{ mx: 2 }}>Or</Typography>

            <Box sx={{ width: '100%', maxWidth: '200px' }}>
                <TextField
                    inputRef={commentRef}
                    variant="standard"
                    fullWidth
                    onChange={(val) => setComment(val.target.value)}
                    error={!isValidComment}
                    placeholder="Enter comment for your job"
                    sx={{ color: isValidComment ? 'customColorStartTrackerForm.colorTextField' : '' }}
                />
            </Box>

            <Button onClick={onStart} variant="contained" sx={{ py: '5px', ml: '13px', lineHeight: '19px' }} disabled={isDisabledStart}>
                Start
            </Button>
        </Stack>
    );
};

export default React.memo(StartTrackerForm);

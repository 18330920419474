import formatEtag from 'helpers/formatEtag';
import { api } from './api';


export const journalApi = api.injectEndpoints({
   tagTypes: ['Budget'],
   endpoints: (builder) => ({
      getClients: builder.query({
         query: () => {
            const params = {};
            // const params = { '$expand': 'Project' };
            params['$orderby'] = 'CreatedAt'
            return {
               url: `Clients`,
               params: { ...params }
            }
         },
         providesTags: [{ type: 'Clients' }]
      }),

      getProjects: builder.query({
         query: ({ clientIds, name }) => {
            const params = {};
            params['$orderby'] = 'CreatedAt'
            let filter = '';

            if (clientIds.length === 1) {
               filter = `ClientId eq ${clientIds.at(0)}`
            }
            if (clientIds.length > 1) {
               filter = `ClientId in (${clientIds.join()})`
            }

            if (filter) {
               params['$filter'] = filter;
            }

            return {
               url: `Projects`,
               params: { ...params }
            }
         },
         providesTags: [{ type: 'Projects' }]
      }),

      getJournalTransactions: builder.query({
         query: ({ projectIds = null, period = null }) => {
            const params = {};
            params['$expand'] = 'HourJournalLines,WorkItem($expand=Project($expand=Organization))'
            params['$orderby'] = 'CreatedAt'
            let filter = [];

            filter.push('Quantity gt 0')

            if (projectIds.length === 1) {
               filter.push(`WorkItem/Project/Id eq ${projectIds.at(0)}`);
            }
            if (projectIds.length > 1) {
               filter.push(`WorkItem/Project/Id in (${projectIds.join()})`);
            }

            if (period) {
               filter.push(`(TransDate ge ${period.from} and TransDate le ${period.to})`)
            }

            if (filter) {
               params['$filter'] = `${filter.join(' and ')}`;
            }

            return {
               url: `Transactions`,
               params: { ...params }
            }
         },
      }),

      getJournalHeaders: builder.query({
         query: () => {
            const params = {};
            params['$orderby'] = 'CreatedAt DESC'
            params['$select'] = 'Id,Description,ProjectId,Posted,ClientId,Client/Name,Project/AlternativeName'
            // let filter = [];

            // filter.push('Quantity gt 0')

            // if (projectIds.length === 1) {
            //    filter.push(`WorkItem/Project/Id eq ${projectIds.at(0)}`);
            // }
            // if (projectIds.length > 1) {
            //    filter.push(`WorkItem/Project/Id in (${projectIds.join()})`);
            // }

            // if (period) {
            //    filter.push(`(TransDate ge ${period.from} and TransDate le ${period.to})`)
            // }

            // if (filter) {
            //    params['$filter'] = `${filter.join(' and ')}`;
            // }

            return {
               url: `HourJournalHeaders`,
               params: { ...params }
            }
         },
         providesTags: [{ type: 'HourLournalHeaders' }]
      }),

      getJournalLinesByHeader: builder.query({
         query: ({ id }) => {
            const params = {};
            params['$orderby'] = 'CreatedAt'
            params['$select'] = 'Id,Comment,WorkItemId,TransDate,Quantity,Transaction/Quantity,Transaction/User/FullName,Transaction/WorkItem/Name,Transaction/Comment,User/FullName,WorkItem/Name,WorkItem/Id,WorkItem/ExternalId'
            let filter = [];

            filter.push(`HeaderId eq ${id}`)

            // if (projectIds.length === 1) {
            //    filter.push(`WorkItem/Project/Id eq ${projectIds.at(0)}`);
            // }
            // if (projectIds.length > 1) {
            //    filter.push(`WorkItem/Project/Id in (${projectIds.join()})`);
            // }

            // if (period) {
            //    filter.push(`(TransDate ge ${period.from} and TransDate le ${period.to})`)
            // }

            if (filter?.length) {
               params['$filter'] = `${filter.join(' and ')}`;
            }

            return {
               url: `HourJournalLines`,
               params: { ...params }
            }
         },
      }),

      addJournalHeader: builder.mutation({
         query: ({ body }) => {

            return {
               url: `HourJournalHeaders`,
               method: 'POST',
               body: body
            }
         },
         invalidatesTags: [{ type: 'HourLournalHeaders' }]
      }),

      updateJournalHeader: builder.mutation({
         query: ({ id, body, etag }) => {
            return {
               url: `HourJournalHeaders(${id})`,
               method: 'PATCH',
               body: body,
               headers: { 'if-match': formatEtag(etag) }
            }
         },
         invalidatesTags: [{ type: 'HourLournalHeaders' }]
      }),

      validateJournalHeader: builder.mutation({
         query: ({ id }) => {
            return {
               url: `HourJournalHeaders(${id})/TimeTracking.DataEntities.ValidateHourJournal`,
               method: 'POST',
            }
         },

      }),
   })
})

export const { useGetClientsQuery, useGetProjectsQuery, useGetJournalTransactionsQuery, useGetJournalHeadersQuery, useGetJournalLinesByHeaderQuery, useAddJournalHeaderMutation, useUpdateJournalHeaderMutation, useValidateJournalHeaderMutation } = journalApi

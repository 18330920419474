import React, { useCallback, useEffect, useRef, useState } from 'react';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { useGetFilterOptionsQuery } from 'services/task';
import CustomMultipleFilter from './CustomMultipleFilter';
import { debounce } from 'lodash';

const SCROLL_HEIGHT = 300;
const DEFAULT_LIMIT = 20;
const DEFAULT_FIELD_NAME = 'Name';
const ENTITY_PROJECT = 'Projects';

const CustomSelectFilter = (props) => {
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    const [filterText, setFilterText] = useState('');
    const { data, isLoading } = useGetFilterOptionsQuery(
        {
            entity: props?.filterEditorProps?.entity,
            skip: 0,
            top: limit,
            text: filterText,
            orgsToFilter:
                props?.entity === ENTITY_PROJECT && props?.filterEditorProps?.orgs?.length > 0 ? props?.filterEditorProps?.orgs : null,
            fieldName: props?.filterEditorProps?.entityNameField ? props.filterEditorProps.entityNameField : DEFAULT_FIELD_NAME
        },
        { skip: !props }
    );

    const [selectProps, setSelectProps] = useState(null);
    const isMaxReached = useRef(false);

    const loadMoreItemsDebounce = debounce((event) => {
        if (isMaxReached.current) return;

        if (event.target.scrollHeight - event.target.offsetHeight - event.target.scrollTop < 20) {
            if (data.count > limit + 60 && limit < 400) {
                setLimit((prev) => prev + DEFAULT_LIMIT);
            } else {
                isMaxReached.current = true;
                setLimit(data['@odata.count']);
            }
        }
    }, 100);

    const loadMoreItems = useCallback(
        (event) => {
            loadMoreItemsDebounce(event);
        },

        [data, limit]
    );

    useEffect(() => {
        if (data?.value && data.value.length < DEFAULT_LIMIT && data.count > DEFAULT_LIMIT) {
            setLimit((data.value.length + DEFAULT_LIMIT) * 2);
        }
    }, [data]);

    const clearFilters = useCallback(() => {
        setFilterText('');
        setLimit(DEFAULT_LIMIT);
        isMaxReached.current = false;
    }, []);

    useEffect(() => {
        setLimit(DEFAULT_LIMIT);
    }, [filterText]);

    useEffect(() => {
        return () => {
            clearFilters();
        };
    }, []);

    useEffect(() => {
        if (data?.value?.length > 0) {
            setSelectProps(() => {
                return {
                    ...props,
                    nativeScroll: true,
                    filterEditorProps: {
                        ...props.filterEditorProps,
                        onFilterValueChange: (e) => setFilterText(e),
                        onCollapse: clearFilters,
                        onScroll: loadMoreItems,
                        dataSource: data?.value,
                        enablePagination: true
                    }
                };
            });
        }
    }, [props, data]);

    return (
        selectProps && (
            <>
                <CustomMultipleFilter {...selectProps} />
            </>
        )
    );
};

export default CustomSelectFilter;

import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Stack, IconButton, Tooltip, Typography, Skeleton } from '@mui/material';
import { useUpdateTransactionMutation, useCreateRecordMutation, useGetCurrentTimeTransactionQuery, trackerApi } from 'services/tracker';
import { onComplete, onPause, onPlay, onPlayWithoutTask, setCurTime } from 'store/reducers/tracker';
import { CheckCircleOutlined, PlayCircleOutlined, PauseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import StartTrackerForm from './StartTrackerForm';
import { useLocalStorage } from 'hooks/useLocalStorage';
import './tracker.css';
import { formatQuantity } from 'helpers/transactionTimeParser';
import { useGetGlobalParamsQuery } from 'services/task';

const MINUTES_WHEN_SHOULD_UPDATE = 15;
const MMINNUTE_IN_MILISECONDS = 60 * 1000;
const INTERVAL_IN_MILISECONDS = 5000;

function caclculateMinutesFromMiliseconds(dateStarted) {
    return Math.trunc((Date.now() - dateStarted) / MMINNUTE_IN_MILISECONDS);
}

let countRequests = 0;

export const Tracker = () => {
    const dispatch = useDispatch();
    const { data: globalParams } = useGetGlobalParamsQuery();
    const [timeToShow, setTimeToShow] = useState(0);
    const [localTrackerState, setLocalTrackerState] = useLocalStorage('trackerState');
    const { isPlaying, activeTaskId, isOpenModal, isPause } = useSelector((state) => state.tracker);
    const userId = useSelector((state) => state.auth.userId);
    const { data: transaction, isLoading, refetch } = useGetCurrentTimeTransactionQuery({ userId }, { skip: !userId });
    const [refetchTransaction] = trackerApi.endpoints.getCurrentTimeTransaction.useLazyQuery();
    const [trigger] = useCreateRecordMutation();
    const [updateTransaction] = useUpdateTransactionMutation();
    const tempTime = useRef(0);
    const dateWhenTimerStarted = useRef(null);
    const intervalId = useRef(null);
    const curTransaction = useRef(null);

    const createRecord = () => {
        let body = {};
        if (activeTaskId) {
            body.WorkItemId = activeTaskId;
            body.MinutesQuantity = 0;
        }
        trigger({ userId, body });
    };

    useEffect(() => {
        if (!isLoading && transaction && !isPlaying && !isOpenModal) {
            if (localTrackerState) {
                if (localTrackerState?.isPlaying && transaction?.WorkItemId) {
                    dispatch(onPlay(transaction?.WorkItemId));
                } else if (localTrackerState?.isPlaying && !transaction?.WorkItemId) {
                    dispatch(onPlayWithoutTask());
                }
                if (localTrackerState?.isPlaying && localTrackerState.isPause) {
                    dispatch(onPause());
                }

                if (localTrackerState?.isPlaying && localTrackerState.tempTime) {
                    tempTime.current = localTrackerState.tempTime;
                }
            }
        }
        if (transaction && (transaction.MinutesQuantity || transaction.MinutesQuantity === 0) && localTrackerState?.tempTime) {
            setTimeToShow(transaction.MinutesQuantity + localTrackerState.tempTime);
        } else if (transaction && (transaction.MinutesQuantity || transaction.MinutesQuantity === 0)) {
            setTimeToShow(transaction.MinutesQuantity);
        }

        curTransaction.current = transaction;

        if (transaction && (transaction?.MinutesQuantity || transaction.MinutesQuantity === 0)) {
            dispatch(
                setCurTime(
                    localTrackerState?.tempTime ? transaction?.MinutesQuantity + localTrackerState.tempTime : transaction?.MinutesQuantity
                )
            );
        }
    }, [transaction, isLoading]);

    useEffect(() => {}, [transaction, isLoading]);

    const updateTime = async (timeSpent) => {
        tempTime.current = 0;
        setLocalTrackerState({ ...localTrackerState, tempTime: 0 });
        let { data } = await refetchTransaction({ userId });

        let body = {
            UserId: userId,
            WorkItemId: activeTaskId,
            Id: data.Id,
            etag: data['@odata.etag']
        };

        if (data?.MinutesQuantity) {
            body.MinutesQuantity = data.MinutesQuantity + timeSpent;
        } else {
            body.MinutesQuantity = timeSpent;
        }

        try {
            const res = await updateTransaction(body).unwrap();
            refetch();
            if (res) {
                startTimer();
                countRequests = 0;
            }
        } catch (err) {
            if ((err?.status === 401 || err?.status === 412) && countRequests < 5) {
                countRequests += 1;
                refetch();
                let x = setTimeout(async () => {
                    await updateTime(timeSpent);
                    clearTimeout(x);
                }, 1000);
            }
        }
    };

    const startTimer = () => {
        if (isPause) return;
        dateWhenTimerStarted.current = Date.now();
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }
        intervalId.current = setInterval(() => {
            let timeSpent = Math.trunc((Date.now() - dateWhenTimerStarted.current) / (60 * 1000));
            if (tempTime.current >= 1 && timeSpent.toString().length === 2) {
                timeSpent += tempTime.current;
            }
            dispatch(setCurTime(timeSpent));

            setTimeToShow((prev) => {
                if (timeSpent) {
                    setLocalTrackerState({ ...localTrackerState, isPlaying, isPause, tempTime: timeSpent });
                    return transaction.MinutesQuantity + timeSpent;
                }
                return prev;
            });
            if (timeSpent != 0 && timeSpent % (globalParams?.MinQty ? globalParams?.MinQty : MINUTES_WHEN_SHOULD_UPDATE) === 0) {
                updateTime(timeSpent);
                clearInterval(intervalId.current);
            }
        }, INTERVAL_IN_MILISECONDS);
    };

    useEffect(() => {
        if (isPlaying) {
            let x = setTimeout(() => {
                setLocalTrackerState({ ...localTrackerState, isPlaying: true, isPause });
                clearTimeout(x);
            }, 0);
            if (!curTransaction.current) {
                createRecord();
            }
            startTimer();
        } else {
            if (intervalId.current) {
                setLocalTrackerState({ ...localTrackerState, isPlaying: false, isPause, tempTime: 0 });
                tempTime.current = 0;
                dateWhenTimerStarted.current = null;
                clearTimeout(intervalId.current);
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        return () => {
            dispatch(onPause());
        };
    }, []);

    useEffect(() => {
        if (isPause) {
            if (intervalId.current) {
                let localValToUpdate = { ...localTrackerState, isPlaying, isPause: isPause };
                const time = caclculateMinutesFromMiliseconds(dateWhenTimerStarted.current);
                setLocalTrackerState({ ...localTrackerState, isPlaying, isPause: isPause, tempTime: tempTime.current });
                if (time < (globalParams?.MinQty ? globalParams?.MinQty : MINUTES_WHEN_SHOULD_UPDATE) && time >= 1) {
                    tempTime.current += time;
                    localValToUpdate.tempTime = tempTime.current;
                }
                setLocalTrackerState(localValToUpdate);
                dateWhenTimerStarted.current = null;
                clearTimeout(intervalId.current);
            }
        } else {
            if (isPlaying) {
                setLocalTrackerState({ ...localTrackerState, isPlaying, isPause: isPause });
                startTimer();
            }
        }
    }, [isPause]);

    useEffect(() => {
        if (activeTaskId != curTransaction.current?.WorkItemId && isPlaying) {
            clearInterval(intervalId.current);
            dateWhenTimerStarted.current = null;
            tempTime.current = 0;
            setLocalTrackerState({ ...localTrackerState, isPlaying, isPause, tempTime: 0 });
            intervalId.current = null;
            startTimer();
            setTimeToShow(0);
        }

        if (activeTaskId && activeTaskId != curTransaction.current?.WorkItemId && curTransaction.current) {
            let body = {
                WorkItemId: activeTaskId,
                MinutesQuantity: 0,
                Id: curTransaction.current.Id,
                etag: curTransaction.current['@odata.etag']
            };
            updateTransaction(body);
            setTimeToShow(0);
        }
    }, [activeTaskId]);

    useEffect(() => {
        if (isOpenModal) {
            clearInterval(intervalId.current);
            if (curTransaction.current?.MinutesQuantity === 0 && curTransaction.current) {
                updateTime(globalParams?.MinQty ? globalParams?.MinQty : MINUTES_WHEN_SHOULD_UPDATE);
            }
            setLocalTrackerState({ ...localTrackerState, isPlaying, isPause, tempTime: 0 });
            dateWhenTimerStarted.current = null;
            tempTime.current = 0;
            intervalId.current = null;
        }
    }, [isOpenModal]);

    const onStart = () => {
        if (activeTaskId) {
            dispatch(onPlay(activeTaskId));
        } else if (transaction?.WorkItemId) {
            dispatch(onPlay(transaction.WorkItemId));
        } else if (transaction?.Comment && !transaction?.WorkItemId) {
            dispatch(onPlayWithoutTask());
        }
    };

    const onStartFromForm = (transaction) => {
        curTransaction.current = transaction;
        if (transaction?.WorkItemId) {
            dispatch(onPlay(transaction?.WorkItemId));
        } else if (transaction?.Comment && !transaction?.WorkItemId) {
            dispatch(onPlayWithoutTask());
        }
    };

    return (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 2 }}>
                {!isPlaying ? (
                    <Tooltip title={'Start track time'} placement="right">
                        <span>
                            <IconButton
                                onClick={onStart}
                                disabled={!transaction}
                                sx={{
                                    borderRadius: '100%',
                                    color: 'customColorTracker.colorIconButton2',
                                    fontSize: '35px',
                                    '&:hover': { bgcolor: 'primary.lighter', color: 'primary.main' },
                                    '&:active': { bgcolor: 'customColorTracker.colorIconButton2', color: 'customColorTracker.colorWhite' }
                                }}
                            >
                                <PlayCircleOutlined />
                            </IconButton>
                        </span>
                    </Tooltip>
                ) : null}

                {isPlaying && !isPause ? (
                    <Tooltip title={'Pause'} placement="right">
                        <IconButton
                            onClick={() => {
                                dispatch(onPause());
                            }}
                            sx={{
                                borderRadius: '100%',
                                fontSize: '35px',
                                color: 'primary.main',
                                height: 'fit-content',
                                width: 'fit-content',
                                '&:active': { bgcolor: 'primary.lighter', color: 'primary.main' }
                            }}
                        >
                            <PauseCircleOutlined />
                        </IconButton>
                    </Tooltip>
                ) : null}

                {isPlaying && isPause ? (
                    <Tooltip title={'Continue'} placement="right">
                        <IconButton
                            onClick={onStart}
                            sx={{
                                borderRadius: '100%',
                                fontSize: '35px',
                                color: 'primary.main',
                                height: 'fit-content',
                                width: 'fit-content',
                                '&:active': { bgcolor: 'primary.lighter', color: 'primary.main' }
                            }}
                        >
                            <PlayCircleOutlined />
                        </IconButton>
                    </Tooltip>
                ) : null}

                {!transaction && !isLoading && userId ? <StartTrackerForm startPlaying={onStartFromForm} /> : null}

                {transaction && !isLoading && userId ? (
                    <Box sx={{ p: 0.5, maxWidth: '457px', minWidth: '200px' }}>
                        <Typography sx={{ fontWeight: '700', fontSize: '11px' }}>You work on:</Typography>

                        <Typography
                            sx={{ fontSize: '14px', color: 'grey.500', overflow: 'hidden', whiteSpace: 'noWrap', textOverflow: 'ellipsis' }}
                        >
                            {transaction?.WorkItem?.Name ? transaction.WorkItem.Name : transaction?.Comment}
                        </Typography>
                    </Box>
                ) : !transaction && isLoading ? (
                    <Skeleton animation="wave" variant="rectangular" width={250} height={40} />
                ) : null}

                {isPlaying ? (
                    <Tooltip title="End track time" placement="right">
                        <IconButton
                            onClick={() => {
                                dispatch(onComplete());
                            }}
                            sx={{
                                borderRadius: '100%',
                                color: 'customColorTracker.colorIconButton',
                                fontSize: '35px',
                                height: 'fit-content',
                                width: 'fit-content',
                                '&:active': { bgcolor: 'primary.lighter' }
                            }}
                        >
                            <CheckCircleOutlined />
                        </IconButton>
                    </Tooltip>
                ) : null}

                {((isPlaying && !isPause) || (isPlaying && isPause)) && (
                    <Box sx={{ p: '2px 4px' }}>
                        <Typography sx={{ fontSize: '28px', color: 'customColorTracker.colorTypography' }}>
                            {formatQuantity(timeToShow || 0)
                                .split('')
                                .map((el) => {
                                    return <span className={el === ':' ? 'blink' : ''}>{el}</span>;
                                })}
                        </Typography>
                    </Box>
                )}
            </Stack>
            {/* <>
                <button onClick={() => dispatch(onPlay(1))}>play</button>
                <button onClick={() => dispatch(onPlay(2))}>play2</button>
                <button onClick={() => dispatch(onPause())}>pause</button>
                <div>{activeTaskId}</div>
                <div>{isPlaying ? 'playing' : 'paused'}</div>
            </> */}
        </Box>
    );
};

import React, { useState } from 'react';
import { useGetNotificationQuery, useMoveToArchiveMutation } from 'services/notification';
import NotificationList from './NotificationList';
import { useSelector } from 'react-redux';
import { useOdataBatchMutation } from 'services/batchRequest';
import { generateCall } from 'helpers/batchGenerate';
import { toast } from 'react-toastify';

const DEFAULT_TOP = 50;

const NOTIFICATION_TYPE = {
    Error: '#A61C23',
    Warning: '#D89615',
    Info: '#12A8A8'
};

const Notifications = () => {
    const [isArchived, setIsArchived] = useState(false);
    const [top, setTop] = useState(DEFAULT_TOP);
    const [topNotificationArhived, setTopNotificationArhived] = useState(DEFAULT_TOP);
    const [batchRequest] = useOdataBatchMutation();

    const userId = useSelector((state) => state.auth.userId);

    const { data, isLoading, isFetching, refetch } = useGetNotificationQuery(
        { userId: userId, isArchived: false, top: top },
        { skip: !userId }
    );
    const {
        data: arhivedNitifications,
        isLoading: isLoadingArhived,
        refetch: refetchArchived
    } = useGetNotificationQuery({ userId: userId, isArchived: true, top: topNotificationArhived }, { skip: !userId });

    const toggleIsArchived = () => setIsArchived((prev) => !prev);

    const handleScrollEnd = () => {
        if (isArchived) {
            if (topNotificationArhived > arhivedNitifications?.value?.length) return;
            setTopNotificationArhived((prev) => {
                return prev + DEFAULT_TOP;
            });
        } else {
            if (top > data?.value?.length) return;

            setTop((prev) => {
                return prev + DEFAULT_TOP;
            });
        }
    };

    const lastNotificationType = data?.value?.[0]?.Type;

    const notifacationCountColor = lastNotificationType ? NOTIFICATION_TYPE[lastNotificationType] || '#12A8A8' : '#12A8A8';

    const items = isArchived ? arhivedNitifications?.value : data?.value;

    const archiveAll = () => {
        let ids = items.map((el) => el.Id);

        let calls = ids.map((id) => {
            return generateCall({ url: `Notifications(${id})/TimeTracking.DataEntities.MoveToArchive`, method: 'POST' });
        });

        if (!calls.length) return;

        batchRequest([calls]).then((res) => {
            if (res?.data?.status) {
                toast.success('All notifications archived successfully');
                refetch();
                refetchArchived();
            }
        });
    };
    return (
        <NotificationList
            notifacationCountColor={notifacationCountColor}
            arhivedNitifications={arhivedNitifications?.[`@odata.count`]}
            notArhivedNotifacationCount={data?.[`@odata.count`]}
            isLoading={isLoading || isLoadingArhived}
            notifications={items}
            toggleIsArchived={toggleIsArchived}
            onArchiveAll={archiveAll}
            onScrollEnd={handleScrollEnd}
        />
    );
};

export default Notifications;

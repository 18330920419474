import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import ErrorIcon from '@mui/icons-material/Error';

import InfoIcon from '@mui/icons-material/Info';
import ValueWithLineClamp from 'components/ValueWithLineClamp';
import { useMoveToArchiveMutation } from 'services/notification';

export const NotificationItem = ({ item, onOpenDetails, hasArchive }) => {
    const [moveToArchive, { isLoading }] = useMoveToArchiveMutation();
    const [isDeleted, setIsDeleted] = useState(false);

    const getNotificationIcon = (type) => {
        switch (type) {
            case 'Error':
                return <ErrorIcon color="error" sx={{ transform: 'translateY(4px)', fontSize: '24px' }} />;
            case 'Warning':
                return <ErrorIcon color="warning" sx={{ transform: 'translateY(4px)', fontSize: '24px' }} />;
            case 'Info':
                return <InfoIcon color="info" sx={{ transform: 'translateY(4px)', fontSize: '24px' }} />;
            default:
                return null;
        }
    };

    const handleArchive = (id) => {
        if (!id) return;
        moveToArchive(id);
        setIsDeleted(true);
    };

    const modificateDate = new Date(`${item.CreatedAt}`).toLocaleString();
    const createdDate = new Date(`${item.CreatedAt}`).toLocaleDateString();
    return (
        <Box>
            <Paper
                sx={{
                    position: 'relative',
                    border: `1px solid ${item.Type === 'Error' ? '#A61C23' : item.Type === 'Warning' ? '#D89615' : '#12A8A8'}`,
                    marginBottom: '12px',
                    padding: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '&:hover': {
                        cursor: isDeleted ? 'not-allowed' : 'pointer'
                    },
                    opacity: isDeleted ? '0.4' : '1'
                }}
                onClick={() => {
                    if (isDeleted) return;
                    onOpenDetails();
                }}
                key={item.Id}
            >
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', paddingRight: '40px' }}>
                    <Tooltip title={item.Type}>
                        <Box sx={{ mr: '10px' }}>{getNotificationIcon(item.Type)}</Box>
                    </Tooltip>

                    <Tooltip placement="bottom" title={modificateDate}>
                        <Box>
                            <ValueWithLineClamp openOnHover={true} textSx={{ wordBreak: 'break-word' }}>
                                {item.Title}
                            </ValueWithLineClamp>
                            <Box sx={{ fontSize: '10px', mt: 1 }}>{createdDate}</Box>
                        </Box>
                    </Tooltip>

                    {hasArchive && (
                        <Box sx={{ position: 'absolute', right: '10px', top: '50%', transform: 'translate(0, -50%)' }}>
                            <Tooltip placement="bottom" title="Archive">
                                <IconButton
                                    disabled={isLoading || isDeleted}
                                    sx={{ fontSize: '12px' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleArchive(item.Id);
                                    }}
                                >
                                    <ArchiveIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};
export default NotificationItem;

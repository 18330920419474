import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAttachedModalOpen: false,
    links: [],
    attachEntity: null,
    attachId: null,
    withAttach: false,
    wasUploaded: {entity: null, id: null}
};

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        attachModalToggle(state) {
            state.isAttachedModalOpen = !state.isAttachedModalOpen;
        },
        setAttachLinks(state, action) {
            state.links = action.payload;
        },
        setAttachEntity(state, action) {
            state.attachEntity = action.payload;
        },
        setAttachId(state, action) {
            state.attachId = action.payload;
        },
        setWithAttach(state, action) {
            state.withAttach = action.payload;
        },
        setWasUploaded(state, action) {
            state.wasUploaded = action.payload;
        },
        clearState(state) {
            state = initialState;
        }
    }
});

export default contentSlice.reducer;

export const {
    attachModalToggle,
    setAttachLinks,
    setAttachEntity,
    setAttachId,
    clearState,
    setWithAttach,
    setWasUploaded
} = contentSlice.actions;

import { useSelector } from "react-redux";
import { useAddTableSettingMutation, useGetTableSettingOneQuery, useUpdateTableSettingMutation } from "services/userSettings"
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const useUserProfileSettings = () => {
   const userId = useSelector((state) => state.auth.userId);

   const settingsName = `${userId}ProfileSettings`

   const [addTableSetting] = useAddTableSettingMutation()
   const [updateTableSetting] = useUpdateTableSettingMutation()

   const [value, setValue] = useState({})

   const { data, isFetching, isLoading } = useGetTableSettingOneQuery({ userId, name: settingsName })

   const getValueFromJson = (json) => {
      if (!json) return {}

      try {
         return JSON.parse(json)
      } catch (e) {
         return {}
      }
   }

   useEffect(() => {
      if (!isFetching) {
         let res = getValueFromJson(data?.value?.at(0)?.Data);

         if (!res) return;

         setValue(prev => ({ ...prev, ...res }))
      }
   }, [data, isFetching])

   const successUpdateNotif = () => {
      toast.success('Profile settings were updated')
   }


   const updateProfileSettings = async (newValue) => {
      if (!value && data?.value?.length === 0) {
         try {
            await addTableSetting({ SettingName: settingsName, UserId: userId, Data: JSON.stringify({ ...newValue, isProfileSettings: true }) })
            successUpdateNotif()
         } catch {

         }
      } else {
         try {
            await updateTableSetting({ id: data.value.at(0).Id, etag: data.value.at(0)['@odata.etag'], data: { Data: JSON.stringify({ ...value, ...newValue }) } })
            successUpdateNotif()
         } catch {

         }
      }
   }


   return { data: value, isLoading, isFetching, updateProfileSettings }
}

export default useUserProfileSettings
import { ODataBatch } from 'odata-batch';

const baseURL = `${process.env.REACT_APP_API_URI}data/`

const calls = [
   /* {
       method: 'PATCH',
       url: 'https://tt-dev.outsmart.team/data/TrackedTransactions(13)',
       headers: { 'if-match': `W/"MTM7MjsxNjc2ODEyMjEyLjg0NzUzNA=="` },
       data: {
           MinutesQuantity: 15
       }
   } */
   {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URI}data/WorkItems(2)`
   }
];
// Can add your own headers. Authorization header will be override auth param. Accept and Content-type headers will be ignored.
/* const headers = {
    'Accept-Encoding': 'gzip, deflate',
    Accept: 'application/json;odata.metadata=full',
    'Accept-Charset': 'UTF-8',
    'Cache-Control': 'no-cache'
}; */

export const generateCall = ({ url, body, etag, method, headers = {} }) => {
   let etagFormatted = '';

   if (etag) {
      etagFormatted = etag.replaceAll('\\', '')
   }

   const res = {
      method: method,
      url: baseURL + url,
      headers: { ...headers },
      data: body ? body : { 'empty': true }

   }

   if (etagFormatted) {
      res.headers = { ...res.headers, 'if-match': etagFormatted }
   }
   return res
}

/* const headers = {
   Accept: 'application/json;odata.metadata=full',
   Authorization: 'Basic OjBBbUszQVpoUE1WenNLZ09QcDVkQkNzVElmVUo1ekZlVFE4cHcwcDd0Y1ZXejM5dnhLY3lYUg=='
}; */

export const parseBatchErrors = (errors, notifier) => {
   errors.forEach(err => {
      notifier.error(`CODE: ${err?.data?.error?.code} MESSAGE: ${err?.data?.error?.message}`)
   })
}

export const config = {
   url: `${process.env.REACT_APP_API_URI}data/$batch`,
   /*  headers, */
};

export const batchOperationCreator = (config) => new ODataBatch(config);

/* batchOperation
   .send()
   .then(async function (a) {
      console.log(a);
   })
   .catch(function (e) {
      console.error(e);
   });

 */
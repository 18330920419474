import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Tooltip } from '@mui/material';
import packageJson from '../../../package.json';
// project import
import Logo from './Logo';
import config from 'config';

// ==============================|| MAIN LOGO ||============================== //
const appVersion = packageJson.version;
const LogoSection = ({ sx, to }) => (
    <Tooltip title={`v.${appVersion}`}>
        <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
            <Logo />
        </ButtonBase>
    </Tooltip>
);

LogoSection.propTypes = {
    sx: PropTypes.object,
    to: PropTypes.string
};

export default LogoSection;

import React, { useEffect, useState } from 'react';
import { Stack, Input, Typography, ButtonBase, Fade, Modal, Box, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import PauseCircleOutlined from '@ant-design/icons/PauseCircleOutlined';
import ReactDom from 'react-dom';
import useParseTime from 'hooks/useParseTime';
import { useDispatch } from 'react-redux';
import { setIsOpenModal, onConfirmModal, onPlayWithoutTask } from 'store/reducers/tracker';
import {
    useConfirmTransactionMutation,
    useDeleteTransactionMutation,
    useGetCurrentTimeTransactionQuery,
    useUpdateTransactionMutation
} from 'services/tracker';
import { toast } from 'react-toastify';
import { getMinutesFromQuantity, normalizeToQuantity } from 'helpers/transactionTimeParser';
import { useGetGlobalParamsQuery } from 'services/task';
import WorkItemSelect from 'components/WorkItemSelect/index';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'customColorModalTracker.colorBorder',
    boxShadow: 2,
    borderRadius: '5px 5px 5px 5px',
    p: 2
};

const ModalToShow = () => {
    //TOTAL TIME WILL BE GOTTEN FROM REQUEST
    const { data: globalParams } = useGetGlobalParamsQuery();
    const userId = useSelector((state) => state.auth.userId);
    const { isOpenModal } = useSelector((state) => state.tracker);
    const { data, isFetching, refetch } = useGetCurrentTimeTransactionQuery({ userId }, { skip: !userId });
    const [updateTransaction, { isLoading: isUpdating }] = useUpdateTransactionMutation();
    const [deleteTransaction] = useDeleteTransactionMutation();
    const [confirm] = useConfirmTransactionMutation();
    const [comment, setComment] = useState('');
    const [showWarning, setShowWarning] = useState(false);

    const dispatch = useDispatch();

    const [value, setValue] = useState('');

    const { minutesInRowFormat, completeTime, isCorrectFormat, minutesFromInput } = useParseTime({
        minutes: data?.MinutesQuantity,
        value,
        formatCB: (val) => getMinutesFromQuantity(val, globalParams?.MinQty ? globalParams.MinQty : 15)
    });

    const isValueChanged = minutesFromInput != data?.MinutesQuantity || data?.Comment != comment;

    const onChange = (e) => {
        const numericValue = e.target.value.replace(/[^\d:.]/g, '');
        setValue(numericValue);
        setShowWarning(e.target.value !== numericValue);
    };

    const onCancel = () => {
        dispatch(setIsOpenModal(false));
    };

    const onContinue = () => {
        dispatch(setIsOpenModal(false));
        dispatch(onPlayWithoutTask());
    };

    const onReset = () => {
        setValue(minutesInRowFormat);
    };

    useEffect(() => {
        if (data && comment != data.Comment) {
            setComment(data.Comment);
        }
    }, [data]);

    const onDelete = async () => {
        try {
            await deleteTransaction(data.Id).unwrap();
            toast.success('Transaction deleted successfully');
            dispatch(setIsOpenModal(false));
            dispatch(onConfirmModal());
        } catch (e) {
            toast.error(JSON.stringify(e));
        }
    };

    const isValidComment = comment?.length < 3 ? false : !!comment?.match(/^[a-zA-Z0-9_ -?:/|]{3,128}$/);

    const onConfirm = async () => {
        if (minutesFromInput != data.MinutesQuantity) {
            try {
                let res = await updateTransaction({
                    Id: data.Id,
                    MinutesQuantity: minutesFromInput,
                    Comment: data.Comment != comment && comment ? comment : data.Comment,
                    UserId: userId,
                    etag: data['@odata.etag']
                }).unwrap();
                if (res) {
                    dispatch(setIsOpenModal(false));
                    dispatch(onConfirmModal());
                    await confirm(data.Id);
                }
            } catch {}
        } else {
            try {
                dispatch(setIsOpenModal(false));
                dispatch(onConfirmModal());
                const res = await confirm(data.Id);
                if (res) {
                    refetch();
                }
            } catch {}
        }
    };

    const changeWorkItem = async (val) => {
        if (data.WorkItemId === val || (!data.WorkItemId && !val)) return;
        try {
            let res = await updateTransaction({
                Id: data.Id,
                WorkItemId: val,
                etag: data['@odata.etag']
            }).unwrap();
            if (res) {
                toast.success('WorkItem was successfully updated');
            }
        } catch (e) {
            toast.error(JSON.stringify(e));
        }
    };

    const changeTime = async () => {
        if (data.MinutesQuantity === minutesFromInput) return;
        try {
            let res = await updateTransaction({
                Id: data.Id,
                MinutesQuantity: getMinutesFromQuantity(
                    normalizeToQuantity(minutesFromInput),
                    globalParams?.MinQty ? globalParams?.MinQty : 15
                ),
                etag: data['@odata.etag']
            }).unwrap();
            if (res) {
                toast.success('Time Quantity was successfully updated');
            }
        } catch (e) {
            toast.error(JSON.stringify(e));
        }
    };

    const changeComment = async () => {
        if (data.Comment === comment) return;
        try {
            let res = await updateTransaction({
                Id: data.Id,
                Comment: comment,
                etag: data['@odata.etag']
            }).unwrap();
            if (res) {
                toast.success('Comment was successfully updated');
            }
        } catch (e) {
            toast.error(JSON.stringify(e));
        }
    };

    const getTimeStarted = () => {
        const date = new Date(data?.CreatedAt);

        if (date.getHours() * 60 + date.getMinutes() + minutesFromInput > new Date().getHours() * 60 + new Date().getMinutes()) {
            date.setMinutes(new Date().getMinutes() - (minutesFromInput % 60));
            date.setHours(Math.floor(new Date().getHours() - minutesFromInput / 60));
        }
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };

    useEffect(() => {
        if (minutesInRowFormat) {
            setValue(minutesInRowFormat);
        }
    }, [minutesInRowFormat]);

    return (
        <div>
            <Modal open={isOpenModal} onClose={onCancel} hideBackdrop={true} closeAfterTransition sx={{ zIndex: 1000 }}>
                <Fade in={isOpenModal}>
                    <Box sx={style}>
                        {data && (
                            <>
                                <Stack direction="row" justifyContent="space-between">
                                    <WorkItemSelect
                                        fullWidth
                                        bgcolor="grey.100"
                                        debounceMs={200}
                                        onChange={changeWorkItem}
                                        selectedWorktemId={data?.WorkItemId}
                                    />
                                    <ButtonBase
                                        sx={{
                                            mt: 1,
                                            ml: 1,
                                            p: 1,
                                            color: 'customColorModalTracker.colorButtonRed',
                                            width: 'fit-content',
                                            border: '1px solid red',
                                            borderRadius: 1,
                                            '&:hover': {
                                                bgcolor: 'error.main',
                                                color: 'customColorModalTracker.colorBorderWhite'
                                            }
                                        }}
                                        onClick={onDelete}
                                    >
                                        <Stack direction="row" alignItems="center">
                                            <DeleteOutlined />
                                            <Typography sx={{ ml: 1, fontWeight: '500', lineHeight: '1.1em' }}>Delete</Typography>
                                        </Stack>
                                    </ButtonBase>
                                </Stack>

                                <Typography component="h2" sx={{ fontWeight: '500', fontSize: '13px', mb: '5px', mt: '16px' }}>
                                    Comment:
                                </Typography>

                                <TextField
                                    variant="standard"
                                    value={comment ? comment : ''}
                                    onBlur={changeComment}
                                    /*    PopoverProps={{
                    disableRestoreFocus: true
                }} */
                                    onChange={(val) => setComment(val.target.value)}
                                    error={!isValidComment && comment?.length > 0}
                                    helperText={!isValidComment && comment?.length > 0 ? 'Min length is 3 characters' : ' '}
                                    placeholder="Enter comment for your job"
                                    sx={{
                                        maxWidth: '385px',
                                        width: '100%',
                                        mb: '20px',
                                        color: isValidComment ? 'customColorModalTracker.colorValidComment' : '',
                                        '& .MuiFormHelperText-root': { position: 'absolute', bottom: 0, transform: 'translateY(100%)' }
                                    }}
                                />

                                <Typography variant="h6" component="h2" sx={{ fontWeight: '500', fontSize: '16px', mb: '18px' }}>
                                    Task started at: {getTimeStarted()}
                                </Typography>

                                <Typography variant="h6" component="h2" sx={{ fontWeight: '500', fontSize: '16px', mb: '18px' }}>
                                    You spent on task: {isCorrectFormat ? completeTime : '...'}
                                </Typography>

                                <Box sx={{ position: 'relative' }}>
                                    <Typography component="p" sx={{ fontWeight: '500', fontSize: '13px', mb: '9px' }}>
                                        Time spent
                                    </Typography>

                                    <Input
                                        placeholder="1h 10m"
                                        variant="outlined"
                                        sx={{
                                            mb: '9px',
                                            backgroundColor: 'customColorModalTracker.colorInputBg',
                                            border: '1px solid',
                                            padding: '2px 7px',
                                            borderColor: 'customColorModalTracker.colorBorderInput',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            borderRadius: '5px 5px 5px 5px'
                                        }}
                                        id="pinnedTableName"
                                        name="pinnedTableName"
                                        label="pinnedTableName"
                                        value={value}
                                        onBlur={changeTime}
                                        onChange={onChange}
                                    />
                                    {showWarning && (
                                        <Typography
                                            sx={{
                                                position: 'absolute',
                                                fontSize: '10px',
                                                color: 'error.main',
                                                top: '35%',
                                                display: 'block',
                                                position: 'absolute'
                                            }}
                                        >
                                            Only numeric input is allowed.
                                        </Typography>
                                    )}

                                    <Typography
                                        component="p"
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            color: !isCorrectFormat
                                                ? 'customColorModalTracker.colorTypographyRed'
                                                : 'customColorModalTracker.colorTypographyGray'
                                        }}
                                    >
                                        Use next format: HH:MM
                                    </Typography>
                                    <Typography
                                        component="p"
                                        sx={{ fontWeight: '500', fontSize: '12px', color: 'customColorModalTracker.colorTypographyGray' }}
                                    >
                                        h = hours, m = minutes
                                    </Typography>

                                    <Stack direction="row" justifyContent="end" sx={{ marginTop: '33px' }}>
                                        <ButtonBase
                                            sx={{
                                                mt: 1,
                                                mr: 'auto',
                                                p: 1,
                                                width: 'fit-content',
                                                bgcolor: 'grey.200',
                                                borderRadius: 1,
                                                '&:hover': { bgcolor: 'primary.lighter', color: 'primary.main' }
                                            }}
                                            type="submit"
                                            onClick={onCancel}
                                        >
                                            <Stack direction="row" alignItems="center">
                                                <PauseCircleOutlined />
                                                <Typography sx={{ ml: 1, fontWeight: '500', lineHeight: '1.1em' }}>Pause</Typography>
                                            </Stack>
                                        </ButtonBase>
                                        <ButtonBase
                                            disabled={isValueChanged}
                                            sx={{
                                                mt: 1,
                                                mr: 2,
                                                p: 1,
                                                width: 'fit-content',
                                                bgcolor: 'customColorModalTracker.colorButtonBgRGBA',
                                                borderRadius: 1,
                                                '&:hover': { bgcolor: 'customColorModalTracker.colorButtonBrHoverRGBA' },
                                                '&:disabled': {
                                                    bgcolor: 'customColorModalTracker.colorButtonBrDisabledRGBA',
                                                    cursor: 'not-allowed',
                                                    pointerEvents: 'unset'
                                                }
                                            }}
                                            type="submit"
                                            onClick={onContinue}
                                        >
                                            <Stack direction="row" alignItems="center">
                                                <PlayCircleOutlined />
                                                <Typography sx={{ ml: 1, fontWeight: '500', lineHeight: '1.1em' }}>Continue</Typography>
                                            </Stack>
                                        </ButtonBase>
                                        <ButtonBase
                                            disabled={!isCorrectFormat || isUpdating}
                                            sx={{
                                                mt: 1,
                                                p: 1,
                                                width: 'fit-content',
                                                bgcolor: 'primary.200',
                                                color: 'primary.dark',
                                                borderRadius: 1,
                                                '&:hover': { bgcolor: 'primary.100', color: 'primary.main' }
                                            }}
                                            type="submit"
                                            onClick={onConfirm}
                                        >
                                            <Stack direction="row" alignItems="center">
                                                <SaveOutlined />
                                                <Typography sx={{ ml: 1, fontWeight: '500', lineHeight: '1.1em' }}>Complete</Typography>
                                            </Stack>
                                        </ButtonBase>
                                    </Stack>
                                </Box>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

const ModalTrackerComplete = () => ReactDom.createPortal(<ModalToShow />, document.getElementById('modal'));

export default ModalTrackerComplete;

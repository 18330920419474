import { api } from './api';

export const notificationApi = api.injectEndpoints({
    tagTypes: ['Notification'],
    endpoints: (builder) => ({
        getNotification: builder.query({
            query: ({ userId, isArchived, top }) => {
                const params = {};
                params['$orderby'] = 'CreatedAt DESC';
                params['$top'] = top;
                params['$filter'] = `UserId eq ${userId} and Archived eq ${isArchived}`;
                params['$count'] = `true`;
                return { url: 'Notifications', params };
            },
            providesTags: [{ type: 'Notification' }]
        }),
        moveToArchive: builder.mutation({
            query: (Id) => {
                return {
                    url: `Notifications(${Id})/TimeTracking.DataEntities.MoveToArchive`,
                    method: 'POST'
                };
            },
            invalidatesTags: [{ type: 'Notification' }]
        })
    })
});

export const { useGetNotificationQuery, useMoveToArchiveMutation } = notificationApi;

// third-party
import { configureStore } from '@reduxjs/toolkit';
import { graphApi } from 'services/graph';
import { userSettingsApi } from 'services/userSettings';
import { taskApi } from 'services/task';

// project import
import reducers from './reducers';
import { userAccountsApi } from 'services/userAccounts';
import { activityLogApi } from 'services/activityLog';
import { odataBatchApi } from 'services/batchRequest';
import { budgetApi } from 'services/budget';
import { notificationApi } from 'services/notification';
import { usersApi } from 'services/users';
import { journalApi } from 'services/journal';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(taskApi.middleware)
            .concat(graphApi.middleware)
            .concat(userSettingsApi.middleware)
            .concat(userAccountsApi.middleware)
            .concat(usersApi.middleware)
            .concat(activityLogApi.middleware)
            .concat(odataBatchApi.middleware)
            .concat(budgetApi.middleware)
            .concat(notificationApi.middleware)
            .concat(journalApi.middleware)
});

const { dispatch } = store;

export { store, dispatch };

import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';
import { loginRequest } from 'authConfig';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };
    return (
        <Button
            variant="contained"
            className="ml-auto"
            sx={{
                width: '100%'
            }}
            onClick={() => handleLogin('redirect')}
        >
            Sign in
        </Button>
    );
};

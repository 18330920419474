// types
import { createSlice } from '@reduxjs/toolkit';
import { graphApi } from 'services/graph';
import { userSettingsApi } from 'services/userSettings';

// initial state
const initialState = {
   token: null,
   account: null,
   userId: null,
   entities: []
};

// ==============================|| SLICE - MENU ||============================== //

//ACCOUNT FOR MSAL ACCESS_TOKEN NOT FOR PROFILE DATA

const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      setAccessToken(state, action) {
         state.token = action.payload;
         localStorage.setItem('authtoken', action.payload)
      },
   },
   extraReducers: (builder) => {
      builder.addMatcher(
         graphApi.endpoints.getAccount.matchFulfilled,
         (state, { payload }) => {
            state.account = payload
         }
      ),
         builder.addMatcher(
            userSettingsApi.endpoints.getAccount.matchFulfilled,
            (state, { payload }) => {

               state.entities = Object.fromEntries(Object.entries(Object.groupBy(payload.Entities, ({ Entity }) => Entity)).map(([key, arr]) => [key, Object.groupBy(arr.at(0).Privileges, (el => el.Privilege))]))
               state.userId = payload.UserId
            }
         )
   },
});

export default authSlice.reducer;

export const { setAccessToken } = authSlice.actions;

export const getAccessToken = (state) => state.auth.token
export const getAccount = (state) => state.auth.account
import React, { useEffect, useMemo } from 'react';
import { useGetAccountQuery } from 'services/graph';

const useProfileData = () => {
    const { isLoading, isError, data, error, refetch } = useGetAccountQuery();

    const photo = useMemo(() => {
        //   if (photoBlob) {
        //       return `data:image/jpeg;base64, ${photoBlob}`;
        //   } else {
        return null;
        //   }
    }, []);

    useEffect(() => {
        if (error?.status === 401) {
            const x = setTimeout(() => {
                refetch();
                refetchPhoto();
                clearTimeout(x);
            }, 500);
        }
    }, [isError, error]);

    return [{ ...data, photo: photo }, isLoading];
};

export default useProfileData;

import PropTypes from 'prop-types';
// import packageJson from '../../../../../package.json';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Box, Tooltip } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/Logo';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
    const theme = useTheme();

    // const appVersion = packageJson.version;

    return (
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <Tooltip title={` ${appVersion}`}> */}
                    <Logo sx={{ maxWidth: '150px' }} />
                    {/* </Tooltip> */}

                    {/* <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>v. {appVersion}</Typography> */}
                </Box>
            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;

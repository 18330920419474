import { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            error
        };
    }

    componentDidCatch(error, errorInfo) {
        console.log('🚀 ErrorBoundary', error);
        // You can also log the error to an error reporting service like AppSignal
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;

        if (hasError && !this?.props?.errorComponent) {
            // You can render any custom fallback UI
            return <p>Something went wrong 😭</p>;
        } else if (hasError && this?.props?.errorComponent) {
            return this?.props?.errorComponent;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

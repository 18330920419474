import React, { useCallback, useEffect, useState } from 'react';
import { useGridApiContext, gridFilterModelSelector, useGridSelector } from '@mui/x-data-grid-premium';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FilterListIcon from '@mui/icons-material/FilterList';
import ErrorBoundary from 'components/ErrorBoundary/index';
import { debounce } from 'lodash';

const HeaderFilterWrapper = ({ filterOperators, colDef, item, ...other }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const apiRef = useGridApiContext();

    const selectedOperator = filterOperators.find((op) => item?.operator === op.value) || filterOperators?.at(0);

    const handleFilterOperatorChange = (operator) => {
        let res = { ...item };
        apiRef.current.upsertFilterItem({
            ...res,
            operator: operator
        });
    };

    const handleChange = useCallback(
        debounce((item) => {
            if (!item.value) apiRef.current.deleteFilterItem(item);
            else {
                apiRef.current.upsertFilterItem({
                    ...item
                });
            }
        }, 200),
        []
    );

    useEffect(() => {
        if (!filterOperators.find((el) => el.value === item.operator)) {
            handleFilterOperatorChange(filterOperators.at(0).value);
        }
    }, [item, filterOperators]);

    const filterComponentProps = {
        item,
        selectedOperator: selectedOperator,
        applyValue: handleChange,
        ...selectedOperator?.InputComponentProps,
        chipBoxProps: { sx: { px: '4px', py: '2px', pb: '4px' } }
    };

    const FilterComponent = selectedOperator?.InputComponent;

    return (
        <Stack direction="row" alignItems="center" sx={{ width: '100%', height: '100%' }}>
            <Stack sx={{ maxWidth: 'calc(100% - 30px)', height: '100%', width: '100%', pr: '5px' }} justifyContent="center">
                <ErrorBoundary fallback={'Something went wrong...'}>
                    <FilterComponent {...filterComponentProps} />
                </ErrorBoundary>
            </Stack>
            <Button
                onClick={handleOpenMenu}
                sx={{
                    flexShrink: 0,
                    marginLeft: 'auto',
                    color: 'customColorTableStringFilter.colorButton',
                    minWidth: '22px',
                    px: '5px',
                    fontSize: '20px'
                }}
            >
                <FilterListIcon sx={{ fontSize: '18px' }} />
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                {filterOperators?.map((operator) => (
                    <MenuItem
                        key={operator.label}
                        sx={{
                            backgroundColor:
                                item?.operator?.toLowerCase() === operator?.value?.toLowerCase()
                                    ? 'customColorTableStringFilter.colorMenuItemRGBA'
                                    : ''
                        }}
                        onClick={() => {
                            handleFilterOperatorChange(operator.value);
                            handleCloseMenu();
                        }}
                    >
                        {operator.label}
                    </MenuItem>
                ))}
            </Menu>
        </Stack>
    );
};

export default HeaderFilterWrapper;

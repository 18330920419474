// material-ui
import { createTheme } from '@mui/material/styles';

// third-party
import { presetPalettes, presetDarkPalettes } from '@ant-design/colors';

// project import
import ThemeOption from './theme';
import { THEME_LIGHT } from '../store/reducers/menu';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
    const colors = mode === THEME_LIGHT ? presetPalettes : presetDarkPalettes;

    const greyPrimary = [
        '#ffffff',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000'
    ];

    if (mode !== THEME_LIGHT) greyPrimary.reverse();
    colors.grey = [...greyPrimary];

    const paletteColor = ThemeOption(colors, mode);

    return createTheme({
        palette: {
            mode,
            common:
                mode === THEME_LIGHT
                    ? {
                          black: '#000',
                          white: '#fff'
                      }
                    : { black: '#fff', white: '#000' },

            ...{
                ...paletteColor,
                text: {
                    primary: mode === THEME_LIGHT ? paletteColor.grey[700] : paletteColor.grey[500],
                    secondary: paletteColor.grey[500],
                    disabled: paletteColor.grey[400]
                },
                action: {
                    disabled: paletteColor.grey[300]
                },
                background: {
                    paper: paletteColor.grey[50],
                    default: paletteColor.grey[100],
                    grayLight: paletteColor.grey[200]
                }
            }
        }
    });
};

export default Palette;

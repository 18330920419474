export const ONE_MIN_IN_QUANTITY_FORMAT = 1.6666666666666667;

export const getMinutesFromQuantity = (time, minQty = 15) => {
    if (!time) return 0;

    let hourse = +time.toString().replace('-', '').split('.')[0];
    let rowMinutes = time.toString().replace('-', '').split('.')[1];

    let minutes = 0;
    //if (!!rowMinutes) minutes = Math.trunc((rowMinutes.length > 1 ? +rowMinutes : +(rowMinutes + 0)) / ONE_MIN_IN_QUANTITY_FORMAT);
    if (!!rowMinutes) {
        minutes =
            +(
                Math.trunc(
                    (rowMinutes?.length === 1 ? +(+`${rowMinutes}0`) : +rowMinutes.toString().slice(0, 2)) / ONE_MIN_IN_QUANTITY_FORMAT
                )
                    .toString()
                    .slice(0, 2) / minQty
            ).toFixed() * minQty;
    }

    if (minutes !== 0 && minutes % 60 === 0) {
        hourse += minutes / 60;
        minutes = 0;
    }
    //if (minutes.toString().length > 2) minutes = +minutes.toString().substring(0, 2)

    // if (rowMinutes?.toString()[0] === '0') minutes = +minutes.toString().substring(0, 1)
    // if (minutes != 0) {
    //    if ((Math.ceil(minutes / minQty) * minQty) % 60 === 0) {
    //       hourse += 1
    //    }
    //    minutes = (Math.ceil(minutes / minQty) * minQty) % 60
    // }
    if (hourse) {
        minutes += hourse * 60;
    }
    return Math.sign(time) === -1 ? -minutes : minutes;
};

export const getMintesFromTime = (time) => {
    if (!time) return 0;
    let hourse = +time.toString().replace('-', '').split('.')[0];
    let minutes = +time.toString().replace('-', '').split('.')[1];
    if (hourse) {
        minutes += hourse * 60;
    }
    return Math.sign(time) === -1 ? -minutes : minutes;
};

export const formatQuantity = (minutes) => {
    let hours = Math.floor(Math.abs(minutes) / 60);
    let min = Math.abs(minutes) % 60;

    return `${Math.sign(minutes) === -1 ? '-' : ''}${hours ? (hours <= 9 ? '0' + hours + ':' : hours + ':') : '00:'}${min ? (min <= 9 ? '0' + min : min) : '00'
        }`.trim();
};

export const normalizeToQuantity = (minutes) => {
    return (minutes * ONE_MIN_IN_QUANTITY_FORMAT) / 100;
};

export const getQuantityFromTime = (value) => {
    if (!value) return 0;
    let [hourse, minutes] = value.replace('-', '').split(':')
    let res = value.includes('-') ? -Math.abs((+hourse * 60) + +minutes) : (+hourse * 60) + +minutes;

    return normalizeToQuantity(res)
};

import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import PageNotFound from 'pages/PageNotFound';

// render - dashboard
const Tasks = Loadable(lazy(() => import('pages/tasks')));
const TasksPinned = Loadable(lazy(() => import('pages/tasks/TasksPinned')));
const DashboardAssignedToMe = Loadable(lazy(() => import('pages/assignedToMe')));
const DashboardAssignedToMePinned = Loadable(lazy(() => import('pages/assignedToMe/AssigedToMePinned')));
const ActivityLog = Loadable(lazy(() => import('pages/activityLog')));
const WeeklyLog = Loadable(lazy(() => import('pages/weeklyLog')));
const UsersLog = Loadable(lazy(() => import('pages/usersLog')));
const Profile = Loadable(lazy(() => import('pages/profile')));
const TestExcel = Loadable(lazy(() => import('pages/testExcel')));
const Budget = Loadable(lazy(() => import('pages/budget')));
const Calendar = Loadable(lazy(() => import('pages/calendar')));
const Journal = Loadable(lazy(() => import('pages/journal')));

// render - sample page
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardAssignedToMe />
        },
        {
            path: ':pinnedTable',
            element: <DashboardAssignedToMePinned />
        },
        {
            path: '/testExcel',
            element: <TestExcel />
        },
        {
            path: '/tasks',
            element: <Tasks />
        },
        {
            path: '/tasks/:pinnedTable',
            element: <TasksPinned />
        },
        {
            path: 'activity-log',
            element: <ActivityLog />
        },
        { path: 'weekly-log', element: <WeeklyLog /> },
        { path: 'budget', element: <Budget /> },
        { path: 'users-log', element: <UsersLog /> },
        { path: 'calendar', element: <Calendar /> },
        {
            path: 'journal',
            element: <Journal />
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]
};

export default MainRoutes;

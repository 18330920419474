import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';

const hoverResizabled = {
    '&:hover': {
        bgcolor: 'customColorResizableBox.colorHoverBg',
        opacity: '0.8',
        transition: 'all .5s'
    }
};
const MIN_HEIGHT = 300;
const MIN_WIDTH = 350;

const ResizableBox = React.forwardRef(({ sx, children, ...other }, ref) => {
    const refTop = useRef(null);
    const refRight = useRef(null);
    const refBottom = useRef(null);
    const refLeft = useRef(null);

    useEffect(() => {
        const resizeableElement = ref.current;
        const styles = window.getComputedStyle(resizeableElement);
        let width = parseInt(styles.width, 10);
        let height = parseInt(styles.height, 10);

        let xCord = 0;
        let yCord = 0;

        // resizeableElement.style.top = '150px';
        // resizeableElement.style.left = '150px';

        //TOP
        const onMouseMoveTopResize = (event) => {
            event.stopPropagation();
            event.preventDefault();
            const dy = event.clientY - yCord;
            height = height - dy;
            yCord = event.clientY;
            if (height > MIN_HEIGHT) {
                resizeableElement.style.height = `${height}px`;
            }
        };

        const onMouseMoveUpTopResize = (event) => {
            document.removeEventListener('mousemove', onMouseMoveTopResize);
        };

        const onMouseDownTopResize = (event) => {
            event.stopPropagation();
            event.preventDefault();
            yCord = event.clientY;
            const styles = window.getComputedStyle(resizeableElement);
            resizeableElement.style.bottom = styles.bottom;
            resizeableElement.style.top = null;
            document.addEventListener('mousemove', onMouseMoveTopResize);
            document.addEventListener('mouseup', onMouseMoveUpTopResize);
        };

        //Right
        const onMouseMoveRightResize = (event) => {
            event.stopPropagation();
            event.preventDefault();
            const dx = event.clientX - xCord;
            xCord = event.clientX;
            width = width + dx;
            if (width > MIN_WIDTH) {
                resizeableElement.style.width = `${width}px`;
            }
        };

        const onMouseMoveUpRightResize = (event) => {
            document.removeEventListener('mousemove', onMouseMoveRightResize);
        };

        const onMouseDownRightResize = (event) => {
            event.stopPropagation();
            event.preventDefault();
            xCord = event.clientX;
            resizeableElement.style.left = styles.left;
            resizeableElement.style.right = null;
            document.addEventListener('mousemove', onMouseMoveRightResize);
            document.addEventListener('mouseup', onMouseMoveUpRightResize);
        };

        //BOTTOM
        const onMouseMoveBottomResize = (event) => {
            event.stopPropagation();
            event.preventDefault();
            const dy = event.clientY - yCord;
            height = height + dy;
            yCord = event.clientY;
            if (height > MIN_HEIGHT) {
                resizeableElement.style.height = `${height}px`;
            }
        };

        const onMouseMoveUpBottomResize = (event) => {
            document.removeEventListener('mousemove', onMouseMoveBottomResize);
        };

        const onMouseDownBottomResize = (event) => {
            event.stopPropagation();
            event.preventDefault();
            yCord = event.clientY;
            const styles = window.getComputedStyle(resizeableElement);
            resizeableElement.style.top = styles.top;
            resizeableElement.style.bottom = null;
            document.addEventListener('mousemove', onMouseMoveBottomResize);
            document.addEventListener('mouseup', onMouseMoveUpBottomResize);
        };

        //LEFT
        const onMouseMoveLeftResize = (event) => {
            event.stopPropagation();
            event.preventDefault();
            const dx = event.clientX - xCord;
            xCord = event.clientX;
            width = width - dx;
            if (width > MIN_WIDTH) {
                resizeableElement.style.width = `${width}px`;
            }
        };

        const onMouseMoveUpLeftResize = (event) => {
            document.removeEventListener('mousemove', onMouseMoveLeftResize);
        };

        const onMouseDownLeftResize = (event) => {
            event.stopPropagation();
            event.preventDefault();
            xCord = event.clientX;
            resizeableElement.style.right = styles.right;
            resizeableElement.style.left = null;
            document.addEventListener('mousemove', onMouseMoveLeftResize);
            document.addEventListener('mouseup', onMouseMoveUpLeftResize);
        };

        // Mouse down event listener

        const resizerRight = refRight.current;
        resizerRight.addEventListener('mousedown', onMouseDownRightResize);

        const resizerTop = refTop.current;
        resizerTop.addEventListener('mousedown', onMouseDownTopResize);

        const resizerBottom = refBottom.current;
        resizerBottom.addEventListener('mousedown', onMouseDownBottomResize);

        const resizerLeft = refLeft.current;
        resizerLeft.addEventListener('mousedown', onMouseDownLeftResize);

        return () => {
            resizerRight.removeEventListener('mousedown', onMouseDownRightResize);
            resizerTop.removeEventListener('mousedown', onMouseDownTopResize);
            resizerBottom.removeEventListener('mousedown', onMouseDownBottomResize);
            resizerLeft.removeEventListener('mousedown', onMouseDownLeftResize);
        };
    }, []);

    return (
        <Box
            ref={ref}
            sx={{
                ...sx,
                // border: '2px solid red',
                borderRadius: '3px',
                display: 'flex',
                justyfyContent: 'center',
                alignItems: 'center'
            }}
            className="resizeable-box"
            {...other}
        >
            {children}
            <Box
                ref={refLeft}
                sx={{
                    ...hoverResizabled,
                    position: 'absolute',
                    cursor: 'col-resize',
                    height: '100%',
                    left: '0',
                    top: '0',
                    width: '8px'
                }}
                className="resizer rl"
            ></Box>
            <Box
                ref={refTop}
                sx={{
                    ...hoverResizabled,
                    position: 'absolute',
                    cursor: 'row-resize',
                    height: '8px',
                    left: '0',
                    top: '0',
                    width: '100%'
                }}
                className="resizer rt"
            ></Box>
            <Box
                ref={refRight}
                sx={{
                    ...hoverResizabled,
                    position: 'absolute',
                    cursor: 'col-resize',
                    height: '100%',
                    right: '0',
                    top: '0',
                    width: '8px'
                }}
                className="resizer rr"
            ></Box>
            <Box
                ref={refBottom}
                sx={{
                    ...hoverResizabled,
                    position: 'absolute',
                    cursor: 'row-resize',
                    height: '8px',
                    left: '0',
                    bottom: '0',
                    width: '100%'
                }}
                className="resizer rb"
            ></Box>
        </Box>
    );
});
export default ResizableBox;

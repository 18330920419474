import { api } from './api';

const ENTITY = "Users"

export const usersApi = api.injectEndpoints({
   tagTypes: ['Users'],
   endpoints: (builder) => ({
      getUsers: builder.query({
         query: ({ top = null } = {}) => {

            const params = {};

            if (top) {
               params['top'] = top
            }

            return {
               url: `${ENTITY}`,
               params
            }
         },
         providesTags: [{ type: 'Users' }]
      }),
      getUser: builder.query({
         query: ({ id = null } = {}) => {

            return {
               url: `${ENTITY}(${id})`,
            }
         },
         providesTags: [{ type: 'Users' }]
      }),
   }),
})

export const { useGetUsersQuery, useGetUserQuery } = usersApi

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const PRIVILEGES = {
   UPDATE: 'Update',
   SELECT: 'Select',
   INSERT: 'Insert',
   DELETE: 'Delete',
}

export const ENTITIES = {
   WORK_ITEM: 'WorkItem',
   USER_ACCOUNT: 'UserAccount',
   ORGANIZATION: 'Organization',
   PROJECT: 'Project',
   STATE: 'WorkItemState',
   TRANSACTION: 'Transaction',
   BUDGET: 'Budget',
}

const useHasPrivileges = ({ entity: entityToEdit, privilege } = {}) => {
   const entities = useSelector(state => state.auth.entities)

   const isHasPrivilege = useMemo(() => {
      if (Object.keys(entities).length > 0) {

         const hasPrivilege = entities?.[ENTITIES?.[entityToEdit] || entityToEdit]?.[privilege]
         return hasPrivilege

      } else {
         return false
      }


   }, [entities])

   return { isHasPrivilege, allPrivileges: entities }
}

export default useHasPrivileges
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function NotificationDetails({ isOpen = false, onClose, el }) {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            transitionDuration={{ exit: 0, appear: 300, enter: 300 }}
            PaperProps={{ sx: { maxWidth: '400px', width: '100%' } }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{ fontSize: '18px', textAlign: 'center' }}>
                Notification Details
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <DialogContentText sx={{ mb: 1, fontSize: '16px', wordBreak: 'break-word' }} id="alert-dialog-description">
                    Title: {el?.Title}
                </DialogContentText>
                <DialogContentText sx={{ mb: 1, fontSize: '16px' }} id="alert-dialog-description">
                    Date: {new Date(`${el?.CreatedAt}`).toLocaleString()}
                </DialogContentText>
                <DialogContentText sx={{ mb: 1, fontSize: '16px' }} id="alert-dialog-description">
                    Type: {el?.Type}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description" sx={{ wordBreak: 'break-word', fontSize: '14px' }}>
                    Message: {el?.Message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

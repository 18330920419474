import React, { useState } from 'react';
import { Button, Popover, List, Box } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import NotificationItem from './NotificationItem';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import NotificationDetails from './NotificationDetails';

const NOTIFICATION_TAB = 'notification';
const ARHIVED_TAB = 'archived ';
const OPTION_LABEL_STYLES = { fontWeight: 700, fontSize: '12px', color: 'customColorProfile.colorConnectEmailTypography' };
const TAB_STYLES = { width: '50%' };

const NotificationList = ({
    notifications = [],
    toggleIsArchived,
    isLoading,
    onScrollEnd,
    notArhivedNotifacationCount,
    arhivedNitifications,
    notifacationCountColor,
    onArchiveAll
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [details, setDetails] = useState(null);

    const [tab, setTab] = useState(NOTIFICATION_TAB);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (_, newValue) => {
        setTab(newValue);
        toggleIsArchived();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openDetails = (el) => {
        setDetails(el);
    };

    const handleScroll = (e) => {
        const isScrolledToEnd = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (isScrolledToEnd && onScrollEnd) onScrollEnd();
    };

    return (
        <Box sx={{ transform: 'translateY(1px)', ml: 2, position: 'reletive' }}>
            <IconButton
                color="primary"
                sx={{
                    transform: 'rotate(40deg)',
                    borderRadius: '50%'
                    // '&:hover': {
                    //     borderRadius: '50%'
                    // }
                }}
                aria-describedby="notification-popover"
                onClick={handleClick}
            >
                <NotificationsIcon />
                {notArhivedNotifacationCount > 0 && (
                    <Box
                        component="span"
                        sx={{
                            transform: 'rotate(-39deg)',
                            height: '16px',
                            borderRadius: '41%',
                            backgroundColor: notifacationCountColor,
                            position: 'absolute',
                            left: '7px',
                            px: '11px',
                            top: '0px',

                            fontWeight: 'bold',
                            color: 'white',
                            fontSize: '12px'
                        }}
                    >
                        <Box sx={{ transform: 'translateY(5px)', position: 'absolute', top: '-4px', left: '4px' }} component="span">
                            {notArhivedNotifacationCount > 9 ? '9+' : notArhivedNotifacationCount}
                        </Box>
                    </Box>
                )}
            </IconButton>
            <Popover
                id="notification-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <List
                    sx={{
                        width: '500px',
                        padding: 1
                        // maxHeight: '350px'
                        // overflowY: 'auto'
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                                    <Tab label={`Notification (${notArhivedNotifacationCount})`} value={NOTIFICATION_TAB} sx={TAB_STYLES} />
                                    <Tab label={`Archived (${arhivedNitifications})`} value={ARHIVED_TAB} sx={TAB_STYLES} />
                                </TabList>
                            </Box>
                            {isLoading ? (
                                <Box
                                    sx={{
                                        minHeight: '300px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <CircularProgress size={80} />
                                </Box>
                            ) : (
                                <>
                                    <TabPanel
                                        value={NOTIFICATION_TAB}
                                        onScroll={handleScroll}
                                        sx={{ maxHeight: '320px', overflowY: 'auto', pt: 1 }}
                                    >
                                        {NOTIFICATION_TAB && (
                                            <Box sx={{ textAlign: 'end', mb: 1 }}>
                                                <Button disabled={!notifications.length} onClick={onArchiveAll}>
                                                    Archive all
                                                </Button>
                                            </Box>
                                        )}
                                        {notifications?.length > 0 ? (
                                            notifications?.map((el) => {
                                                return (
                                                    <NotificationItem
                                                        key={el.Id}
                                                        item={el}
                                                        onOpenDetails={() => openDetails(el)}
                                                        hasArchive={true}
                                                        labelsStyles={OPTION_LABEL_STYLES}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <Typography> There are no notifications</Typography>
                                        )}
                                    </TabPanel>
                                    <TabPanel onScroll={handleScroll} value={ARHIVED_TAB} sx={{ maxHeight: '320px', overflowY: 'auto' }}>
                                        {notifications?.length > 0 ? (
                                            notifications?.map((el) => {
                                                return (
                                                    <NotificationItem
                                                        onOpenDetails={() => openDetails(el)}
                                                        key={el.Id}
                                                        item={el}
                                                        labelsStyles={OPTION_LABEL_STYLES}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <Typography> There are no archived notifications</Typography>
                                        )}
                                    </TabPanel>
                                </>
                            )}
                        </TabContext>
                    </Box>
                </List>
            </Popover>
            <NotificationDetails
                isOpen={!!details}
                onClose={() => {
                    setDetails(null);
                }}
                el={details}
            />
        </Box>
    );
};

export default NotificationList;

import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

const StringFilter = (props) => {
    const { item, applyValue, selectedOperator } = props;

    const [inputVal, setInputVal] = useState('');

    useEffect(() => {
        if (item.value && !inputVal) setInputVal(item.value);
    }, [item.value]);

    const handleFilterChange = (e) => {
        setInputVal(e.target.value);
        applyValue({ ...item, value: e.target.value });
    };

    return (
        <>
            <TextField
                sx={{
                    'input::placeholder, label': {
                        textTransform: 'capitalize'
                    }
                }}
                value={inputVal}
                onChange={handleFilterChange}
                placeholder={selectedOperator?.label || item?.operator || 'All'}
                variant="standard"
                autoComplete="off"
                onKeyDown={(e) => e.stopPropagation()}
                label={selectedOperator?.label || item?.operator || 'All'}
                fullWidth
            />
        </>
    );
};

export default StringFilter;
